.apiAuthorization .apiList {
  padding: 0 0.41rem;
}
.apiAuthorization .apiList li {
  padding: 0.29rem 0;
  height: 1.19rem;
  border-bottom: 1px solid #F1F1F1;
  overflow: hidden;
}
.apiAuthorization .apiList li .img-icon {
  height: 0.61rem;
  margin-right: 0.22rem;
  vertical-align: -0.2rem;
}
.apiAuthorization .apiList li .spot {
  float: right;
}
.apiAuthorization .apiList li .spot img {
  width: 0.36rem;
}
.apiAuthorization .apiList li span {
  font-size: 0.25rem;
  color: #02172E;
}
.apiAuthorization .apiList li:last-child {
  border-bottom: none;
}
