









































































































.apiAuthorization {
    .apiList {
        padding: 0 0.41rem;

        li {
            padding: 0.29rem 0;
            height: 1.19rem;
            border-bottom: 1px solid #F1F1F1;
            overflow: hidden;

            .img-icon {
                height: 0.61rem;
                margin-right: 0.22rem;
                vertical-align: -0.2rem;
            }

            .spot {
                float: right;

                img {
                    width: 0.36rem;
                }
            }

            span {
                font-size: 0.25rem;
                color: #02172E;
            }
        }

        li:last-child {
            border-bottom: none;
        }
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;